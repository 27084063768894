<template>
  <div class="video-page">
    <video width="730" height="410" controls>
      <source :src="videoList[id].url" type="video/mp4">
    </video>
    <div style="font-size: 20px; color: #b6b9ba;margin-top: 40px">{{videoList[id].title}}</div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "深圳多知能源物联有限公司专注智能家居,烟雾报警器,燃气报警器",
    meta: [
      {
        name: "keywords",
        content: "燃气报警器电子产业互联网,液化石油气LPG,物联网应用软硬件产品,燃企配送移动端",
      },
      {
        name: "description",
        content:
          "优气物联致力于“液化石油气LPG” 物联网应用软硬件产品的研发、生产、销售和服务。旨在为燃气企业赋能，降低成本，提升效率，为监管单位提供高效精准的监管渠道，为终端用户带来高质量的用气体验。打造一个聚焦LPG领域，融合新零售、采用大数据、物联网等前沿技术的一站式服务平台。",
      },
    ],
  },
  data(){
    return {
      videoList: {
        "0": {url: "/video/video1.mp4", title: "切断阀 / 监测器"},
        "1": {url: "/video/video2.mp4", title: "检测报警器"},
        "2": {url: "/video/video3.mp4", title: "2022多知企业形象"},
      }
    }
  },
  computed: {
    id(){
      return this.$route.query.id
    }
  },
}
</script>

<style lang="scss" scoped>
.video-page{
  background: #f0f0f0;
  padding: 30px 0;
}

</style>